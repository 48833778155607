import { Security } from "@okta/okta-react";
import React from "react";
import { UserProvider, oktaAuth } from "../contexts/UserContext";

type WrapperProviderProps = {
  children: React.ReactNode;
};

const WrapperProvider = ({ children }: WrapperProviderProps) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>{children}</UserProvider>
    </Security>
  );
};

export default WrapperProvider;
