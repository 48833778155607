import "best-common-react/lib/styles/bcr.css";
import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import WrapperProvider from "./modules/WrapperProvider";

ReactDom.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("clockpad-app"),
);
