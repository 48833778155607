import { ThemeType, Traditional, TraditionalColors } from "best-common-react";

export const ClockPadColors = {
  // Default BCR Colors
  ...TraditionalColors,
  // Application Specific Colors
  "form-factor-color": {
    joycon: "#ff4545",
    keyboard: Traditional.white,
    playstation: "#006fcd",
    "x-keys": "#32c5ff",
    xbox: "#64ff6c",
  },
  "slate-gray": {
    dark: "#232c3d",
    medium: "#2f3a4f",
    light: "#525f78",
  },
  text: {
    dark: "#05080d",
    primary: "#171f2e",
    light: TraditionalColors.white,
  },
};

const ClockPadTheme: ThemeType = {
  ...Traditional,
  name: "ClockPadTheme",
  fontFamily: '"Roboto", sans-serif',
  background: ClockPadColors["slate-gray"].dark,
  autocomplete: {
    ...Traditional.autocomplete,
    background: ClockPadColors["slate-gray"].dark,
    "border-color": ClockPadColors["slate-gray"].dark,
    color: ClockPadColors.white,
  },
  button: {
    ...Traditional.button,
    primary: {
      "background-color": ClockPadColors["slate-gray"].dark,
      "border-color": ClockPadColors["slate-gray"].dark,
      color: ClockPadColors.white,
      hover: ClockPadColors["slate-gray"].light,
    },
    secondary: {
      "background-color": ClockPadColors["slate-gray"].light,
      "border-color": ClockPadColors["slate-gray"].dark,
      color: ClockPadColors.white,
      hover: ClockPadColors["slate-gray"].light,
    },
  },
  icon: {
    ...Traditional.icon,
    color: ClockPadColors.white,
    hoverColor: ClockPadColors["slate-gray"].dark,
  },
  iconAdorner: {
    ...Traditional.icon,
    color: ClockPadColors.white,
    hoverColor: ClockPadColors["slate-gray"].dark,
  },
  input: {
    ...Traditional.input,
    background: ClockPadColors["slate-gray"].light,
    "border-color": ClockPadColors["slate-gray"].dark,
    color: ClockPadColors.white,
  },
  label: {
    ...Traditional.label,
    color: ClockPadColors.white,
  },
  nav: {
    ...Traditional.nav,
    mobile: {
      ...Traditional.nav.mobile,
      active: {
        ...Traditional.nav.mobile.active,
        background: ClockPadColors["slate-gray"].light,
        color: ClockPadColors.white,
      },
      hover: {
        ...Traditional.nav.mobile.hover,
        background: ClockPadColors["slate-gray"].light,
        color: ClockPadColors.white,
      },
      link: {
        ...Traditional.nav.mobile.link,
        background: ClockPadColors["slate-gray"].medium,
        color: ClockPadColors.white,
        borderColor: ClockPadColors["slate-gray"].dark,
      },
      menu: {
        ...Traditional.nav.mobile.menu,
        background: ClockPadColors["slate-gray"].medium,
        color: ClockPadColors.white,
        link: {
          ...Traditional.nav.mobile.menu.link,
          background: ClockPadColors["slate-gray"].medium,
          color: ClockPadColors.white,
        },
      },
      nav: {
        ...Traditional.nav.mobile.nav,
        background: ClockPadColors["slate-gray"].medium,
      },
    },
  },
  placeholder: {
    ...Traditional.placeholder,
    color: ClockPadColors["slate-gray"].dark,
  },
  textarea: {
    ...Traditional.textarea,
    input: {
      ...Traditional.textarea.input,
      borderColor: ClockPadColors["slate-gray"].dark,
      background: ClockPadColors["slate-gray"].light,
      color: ClockPadColors.white,
    },
    placeholder: {
      ...Traditional.textarea.placeholder,
      color: ClockPadColors.white,
    },
  },
  typography: {
    ...Traditional.typography,
    span: {
      ...Traditional.typography.span,
      color: ClockPadColors.text.light,
    },
    div: {
      ...Traditional.typography.div,
      color: ClockPadColors.text.light,
    },
    body1: {
      ...Traditional.typography.body1,
      color: ClockPadColors.text.light,
    },
    body2: {
      ...Traditional.typography.body2,
      color: ClockPadColors.text.light,
    },
    body3: {
      ...Traditional.typography.body3,
      color: ClockPadColors.text.light,
    },
    caption: {
      ...Traditional.typography.caption,
      color: ClockPadColors.text.light,
    },
    h1: {
      ...Traditional.typography.h1,
      color: ClockPadColors.text.light,
    },
    h2: {
      ...Traditional.typography.h2,
      color: ClockPadColors.text.light,
    },
    h3: {
      ...Traditional.typography.h3,
      color: ClockPadColors.text.light,
    },
    h4: {
      ...Traditional.typography.h4,
      color: ClockPadColors.text.light,
    },
    h5: {
      ...Traditional.typography.h5,
      color: ClockPadColors.text.light,
    },
    h6: {
      ...Traditional.typography.h6,
      color: ClockPadColors.text.light,
    },
    subtitle1: {
      ...Traditional.typography.subtitle1,
      color: ClockPadColors.text.light,
    },
    subtitle2: {
      ...Traditional.typography.subtitle2,
      color: ClockPadColors.text.light,
    },
  },
  select: {
    ...Traditional.select,
    background: ClockPadColors["slate-gray"].light,
    borderColor: ClockPadColors["slate-gray"].dark,
    hover: {
      background: ClockPadColors.black,
      color: ClockPadColors.black,
    },
    placeholder: {
      ...Traditional.select.placeholder,
      color: ClockPadColors["slate-gray"].dark,
    },
  },
};

export default ClockPadTheme;
